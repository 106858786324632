<div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="center center">
  <webcam [width]="width"
          [height]="height"
          [imageType]="imageType"
          [trigger]="webcamTrigger$"
          mirrorImage="never"
          (imageCapture)="handleImageCapture($event)"
          (initError)="handleCameraInitError($event)"></webcam>
  <div fxLayout="row" fxLayoutGap="12px" class="mb-12">
    <button mat-flat-button (click)="takePhoto()" class="btn-black">{{'COMMON.TAKE_PHOTO' | translate}}</button>
    <button mat-flat-button (click)="cancel()">{{'COMMON.CANCEL' | translate}}</button>
  </div>
</div>
