import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from '../../shared.module';
import { DialogComponent } from '../dialog/dialog.component';
import { CameraPhotoComponent, UploadPhotoComponent } from './components';
import { FileApi } from '../../api/file.api';
import { HttpEventType } from '@angular/common/http';
import { ErrorHandlerService } from '../../services';
import { base64ToFile } from 'ngx-image-cropper';

@Component({
  standalone: true,
  selector: 'dt-photo-dialog',
  templateUrl: './photo.dialog.html',
  styleUrls: ['./photo.dialog.scss'],
  imports: [SharedModule, DialogComponent, CameraPhotoComponent, UploadPhotoComponent],
})
export class PhotoDialog {
  public base64Photo: string | null = null;
  public selectedMode: 'CAMERA' | 'FILE' | null = null;
  public isCancelButtonHidden: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<PhotoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fileApi: FileApi,
    private _errorHandlerService: ErrorHandlerService,
  ) {}

  public onChangeMode(selectedMode: 'CAMERA' | 'FILE' | null): void {
    this.isCancelButtonHidden = selectedMode !== null;
    this.selectedMode = selectedMode;
  }

  public handlePhotoBase64(base64Photo: string): void {
    this.base64Photo = base64Photo;
    this.accept();
  }

  public accept(): void {
    if (this.base64Photo === null) {
      return;
    }

    const fileBlob: Blob = base64ToFile(this.base64Photo);
    const file: File = new File([fileBlob], 'warehouse-item-photo.jpeg');

    this._fileApi.uploadFile(file).subscribe({
      next: (response) => {
        switch (response.type) {
          case HttpEventType.Response:
            if (response.body) {
              this._dialogRef.close({ action: 'ACCEPTED', data: { id: response.body.data.id, name: file.name } });
            }
            break;
        }
      },
      error: (err) => {
        this._errorHandlerService.reportError('PHOTO_UPLOAD', 'Upload photo file', err);
      },
    });
  }

  public close(): void {
    this._dialogRef.close({ action: 'CANCELLED', data: { file: null } });
  }
}
