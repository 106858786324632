import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { APP_CONFIG } from '../../inject-tokens';
import { IApiResponse, IAppConfig } from '../../core/interfaces';
import { Observable } from 'rxjs';
import { ICreatedId } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class FileApi {
  constructor(private _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _config: IAppConfig) {}

  public uploadFile(file: File): Observable<HttpEvent<IApiResponse<ICreatedId>>> {
    const formData = new FormData();
    formData.set('file', file);

    return this._httpClient.post<IApiResponse<ICreatedId>>(`${this._config.apiUrl}/files`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
