import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../../../inject-tokens';
import { IApiResponse, IAppConfig } from '../../../core/interfaces';
import { map, Observable } from 'rxjs';
import { IGusData } from './gus.interfaces';

@Injectable()
export class GusApi {
  constructor(private _httpClient: HttpClient, @Inject(APP_CONFIG) private readonly _config: IAppConfig) {}

  public getDataFromGUS(taxId: string): Observable<IGusData> {
    return this._httpClient
      .get<IApiResponse<IGusData>>(`${this._config.apiUrl}/clients/nip/${taxId}`)
      .pipe(map((response) => response.data));
  }
}
