<div [class.no-border]="noBorder" fxLayout="row">
  <div [fxFlex]="labelWidth" class="dt-text dt-text-sm dt-text-semi">{{label ?? '' | translate}}</div>
  <div class="dt-text dt-text-sm dt-break-spaces">
    <ng-container *ngIf="value else content">
      {{value | translate}}
    </ng-container>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  </div>
</div>
