import { Injectable } from '@angular/core';
import { GusApi } from './gus.api';
import { Observable, Subject } from 'rxjs';
import { IGusData } from './gus.interfaces';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../notification';
import { ErrorHandlerService } from '../error-handler';

@Injectable()
export class GusService {
  private _gusData: Subject<IGusData> = new Subject<IGusData>();

  constructor(
    private _gusApi: GusApi,
    private _translateService: TranslateService,
    private _notificationService: NotificationService,
    private _errorHandlerService: ErrorHandlerService,
  ) {}

  public get gusData$(): Observable<IGusData> {
    return this._gusData.asObservable();
  }

  public getDataFromGUS(taxId: string): void {
    this._gusApi.getDataFromGUS(taxId).subscribe({
      next: (gusData) => this._gusData.next(gusData),
      error: (err) => {
        this._errorHandlerService.reportError('SHARED', 'GUS Service', err);
        if (err.error.message === 'Nip not found') {
          this._notificationService.error(this._translateService.instant('CLIENTS.TAX_ID_NOT_FOUND'));
        } else {
          this._notificationService.error(this._translateService.instant('EXCEPTIONS.UNKNOWN_ERROR'));
        }
      },
    });
  }
}
