import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';

@Component({
  standalone: true,
  selector: 'dt-upload-photo-component',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss'],
  imports: [SharedModule, ImageCropperModule],
})
export class UploadPhotoComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) public fileInput?: ElementRef;

  @Input() public width: number = 640;
  @Input() public height: number = 480;

  @Output() public photo: EventEmitter<string> = new EventEmitter<string>();
  @Output() public canceled: EventEmitter<void> = new EventEmitter<void>();

  public imageChangedEvent: Event | null = null;
  private _base64Photo: string | null = null;

  public ngOnInit(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  public handleInputFileChange(event: Event): void {
    this.imageChangedEvent = event;
  }

  public handleCroppedImage(event: ImageCroppedEvent): void {
    this._base64Photo = event.base64 ? event.base64 : null;
  }

  public acceptCrop(): void {
    if (this._base64Photo !== null) {
      this.photo.emit(this._base64Photo);
    }
  }

  public cancel(): void {
    this.canceled.emit();
  }
}
