import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormResetService {
  private _reset: Subject<void> = new Subject<void>();

  public get reset$(): Observable<void> {
    return this._reset.asObservable();
  }

  public reset(): void {
    this._reset.next();
  }
}
