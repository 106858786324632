import { ISelectItem } from '../interfaces';

export const generateYears = (fromYear: number): ISelectItem[] => {
  const years: ISelectItem[] = [];
  const toYear: number = new Date().getFullYear() + 1;
  for (let year = fromYear; year <= toYear; year++) {
    years.push({
      label: year.toString(),
      value: year,
    });
  }
  return years;
};
