<div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="center center">
  <input type="file" #fileInput (change)="handleInputFileChange($event)" accept="image/*" />

  <image-cropper [imageChangedEvent]="imageChangedEvent"
                 [maintainAspectRatio]="true"
                 [containWithinAspectRatio]="true"
                 [aspectRatio]="width / height"
                 (imageCropped)="handleCroppedImage($event)"
                 output="base64"
                 format="jpeg"></image-cropper>

  <div fxLayout="row" fxLayoutGap="12px" class="mb-12">
    <button mat-flat-button (click)="acceptCrop()" class="btn-black">{{'COMMON.TAKE_PHOTO' | translate}}</button>
    <button mat-flat-button (click)="cancel()">{{'COMMON.CANCEL' | translate}}</button>
  </div>
</div>
