<dt-dialog
  label="COMMON.PHOTO"
  acceptButtonText="COMMON.ACCEPT"
  cancelButtonText="COMMON.CANCEL"
  [isAcceptButtonHidden]="true"
  [isCancelButtonHidden]="isCancelButtonHidden"
  (accepted)="accept()"
  (cancelled)="close()">

  <ng-container *ngIf="selectedMode === null">
    <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="center center">
      <button mat-flat-button
              class="btn-black btn-lg"
              (click)="onChangeMode('CAMERA')">{{'WAREHOUSE.PHOTO_FROM_CAMERA' | translate}}</button>
      <button mat-flat-button
              class="btn-black btn-lg"
              (click)="onChangeMode('FILE')">{{'WAREHOUSE.SELECT_PHOTO_FILE' | translate}}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedMode === 'CAMERA'">
    <dt-camera-photo-component (photo)="handlePhotoBase64($event)"
                               (canceled)="onChangeMode(null)"></dt-camera-photo-component>
  </ng-container>

  <ng-container *ngIf="selectedMode === 'FILE'">
    <dt-upload-photo-component (photo)="handlePhotoBase64($event)"
                               (canceled)="onChangeMode(null)"></dt-upload-photo-component>
  </ng-container>
</dt-dialog>
