import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamModule } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { SharedModule } from '../../../../shared.module';
import { ErrorHandlerService, NotificationService } from '../../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'dt-camera-photo-component',
  templateUrl: './camera-photo.component.html',
  styleUrls: ['./camera-photo.component.scss'],
  imports: [SharedModule, WebcamModule],
})
export class CameraPhotoComponent {
  @Input() public width: number = 640;
  @Input() public height: number = 480;
  @Input() public imageType: string = 'image/jpeg';
  @Output() public photo: EventEmitter<string> = new EventEmitter<string>();
  @Output() public canceled: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _errorHandlerService: ErrorHandlerService,
    private _translateService: TranslateService,
    private _notificationService: NotificationService,
  ) {}

  private _webcamTrigger: Subject<void> = new Subject<void>();

  public get webcamTrigger$(): Observable<void> {
    return this._webcamTrigger.asObservable();
  }

  public takePhoto(): void {
    this._webcamTrigger.next();
  }

  public cancel(): void {
    this._webcamTrigger.complete();
    this.canceled.emit();
  }

  public handleImageCapture(webcamImage: WebcamImage): void {
    this._webcamTrigger.complete();
    this.photo.emit(webcamImage.imageAsDataUrl);
  }

  public handleCameraInitError(error: WebcamInitError): void {
    this._errorHandlerService.reportError('SHARED', CameraPhotoComponent.name, error);

    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      this._notificationService.error(this._translateService.instant('EXCEPTIONS.CAMERA_NOT_ALLOWED'));
    }
  }
}
