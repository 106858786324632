import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogResult, IFile } from '../../interfaces';
import { PhotoDialog } from './photo.dialog';

@Injectable({
  providedIn: 'root',
})
export class PhotoDialogService {
  constructor(private _matDialog: MatDialog) {}

  public open(): MatDialogRef<PhotoDialog, IDialogResult<IFile>> {
    return this._matDialog.open(PhotoDialog, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      disableClose: true,
    });
  }
}
