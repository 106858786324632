<div fxLayout="column" fxLayoutGap="12px">

  <div class="files" fxLayout="column" fxLayoutGap="12px">
    <div *ngFor="let file of uploadedFiles"
         class="file"
         fxLayout="row"
         fxLayoutGap="3px"
         fxLayoutAlign="space-between center">
      <div class="name">{{file.name}}</div>
      <button mat-icon-button
              (click)="removedFile.emit(file)"
              [matTooltip]="'COMMON.REMOVE' | translate"
              class="btn-icon-sm" color="warn">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="uploadedFiles.length === 0" class="dt-text dt-text-center">Brak plików</div>
  </div>

  <input type="file" multiple #fileInput (change)="onChangeFiles($event)" />
  <button mat-flat-button class="btn-black" (click)="fileInput.click()">{{'COMMON.ADD_FILES' | translate}}</button>
</div>
