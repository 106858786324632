import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicLayoutContainer } from './layouts/basic/basic.layout';
import { EmptyLayoutContainer } from './layouts/empty/empty.layout';
import { AccessDeniedPage } from './access-denied.page';
import { clientGuard, userGuard } from './core/guards';

const routes: Routes = [
  {
    path: '',
    children: [
      // Redirect empty path
      { path: '', pathMatch: 'full', redirectTo: 'orders' },
      { path: 'welcome', pathMatch: 'full', redirectTo: 'orders' },
      { path: 'unauthenticated', pathMatch: 'full', redirectTo: 'auth' },

      // Guest
      {
        path: '',
        children: [
          {
            path: '',
            component: EmptyLayoutContainer,
            children: [
              {
                path: 'auth',
                loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
              },
              {
                path: 'access-denied',
                component: AccessDeniedPage,
              },
            ],
          },
        ],
      },

      // Logged
      {
        path: '',
        children: [
          {
            path: 'client-panel',
            component: BasicLayoutContainer,
            canActivateChild: [clientGuard],
            children: [
              {
                path: '',
                loadChildren: () => import('./client-panel/client-panel.module').then((m) => m.ClientPanelModule),
              },
            ],
          },
          {
            path: '',
            component: BasicLayoutContainer,
            canActivateChild: [userGuard],
            children: [
              {
                path: 'users',
                loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
              },
              {
                path: 'clients',
                loadChildren: () => import('./modules/clients/clients.module').then((m) => m.ClientsModule),
              },
              {
                path: 'devices-groups',
                loadChildren: () =>
                  import('./modules/devices-groups/devices-groups.module').then((m) => m.DevicesGroupsModule),
              },
              {
                path: 'devices',
                loadChildren: () => import('./modules/devices/devices.module').then((m) => m.DevicesModule),
              },
              {
                path: 'orders',
                loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
              },
              {
                path: 'periodic-orders',
                loadChildren: () =>
                  import('./modules/periodic-orders/periodic-orders.module').then((m) => m.PeriodicOrdersModule),
              },
              {
                path: 'activities',
                loadChildren: () => import('./modules/activities/activities.module').then((m) => m.ActivitiesModule),
              },
              {
                path: 'calendar',
                loadChildren: () => import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
              },
              {
                path: 'warehouse',
                loadChildren: () => import('./modules/warehouse/warehouse.module').then((m) => m.WarehouseModule),
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
