import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormSubmitService {
  private _submit: Subject<void> = new Subject<void>();

  public get submit$(): Observable<void> {
    return this._submit.asObservable();
  }

  public submit(): void {
    this._submit.next();
  }
}
