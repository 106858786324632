import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class MediaWatcherService {
  constructor(private _breakpointObserver: BreakpointObserver) {}

  /**
   * On media query change
   *
   * @param query
   */
  public onMediaQueryChange$(query: string | string[]): Observable<BreakpointState> {
    return this._breakpointObserver.observe(query);
  }

  /**
   * Check if media query is matched
   *
   * @param query
   */
  public isMatched(query: string | string[]): boolean {
    return this._breakpointObserver.isMatched(query);
  }
}
